import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PersonToContact from '../components/PersonToContact';
import PortfolioOverview from '../components/PortfolioOverview';
import ServicesOverview from '../components/ServicesOverview';
import CustomerOverview from '../components/CustomerOverview';

const Services = ({ data, location }) => {
  const serviceItems = data.serviceItems.edges;
  const { html, frontmatter } = data.services;
  const { html: html2 } = data.services2;

  console.log('FOO', { serviceItems });

  return (
    <Layout bodyClass="page-services">
      <SEO
        title="Dienste"
        description={frontmatter.description}
        image={frontmatter.services_image_meta}
        url={location.href}
      />

      <div className="services pt-6 pb-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{frontmatter.title}</h1>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>

      <div className="strip-grey">
        <div className="container pt-6 pb-6">
          <div className="col-12">
            <div className="row">
              <h2>Unsere Dienstleistungen & Lösungen im Überblick</h2>
            </div>
            <div className="row">
              <ServicesOverview />
            </div>
          </div>
        </div>
      </div>

      <div className="services pt-6 pb-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: html2 }} />
            </div>
          </div>
        </div>
      </div>

      <div className="strip-grey">
        <div className="container">
          <div className="col-12">
            <div className="row">
              <PortfolioOverview />
            </div>
          </div>
        </div>
      </div>

      <div className="strip">
        <div className="container pt-6 pb-6">
          <div className="col-12">
            <div className="row" id="cta">
              <PersonToContact
                authorId="sales-rep"
                title="Ihr Ansprechpartner bei Fragen zu unseren Dienstleistungen und für Projektanfragen"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="strip-grey">
        <div className="container pt-6 pb-6">
          <div className="col-12">
            <div className="row">
              <CustomerOverview />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    serviceItems: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            icon
            weight
          }
        }
      }
    }
    services: markdownRemark(fileAbsolutePath: { regex: "/(services.md)/" }) {
      html
      frontmatter {
        title
        description
        services_image_meta
      }
      fields {
        slug
      }
    }
    services2: markdownRemark(
      fileAbsolutePath: { regex: "/(services-2.md)/" }
    ) {
      html
    }
  }
`;

export default Services;
